import { PATHS } from '@/constants/page-paths';

// export const desktopNavItems = [
//   { path: PATHS.HOME, title: 'Chat' },
//   { path: PATHS.MY_TWIN, title: 'My Twin' },
// ];
export const navItems = [
  { path: PATHS.HOME, title: 'Chat' },
  { path: PATHS.EXPLORE_TWINS, title: 'Explore Twins' },
  // { path: PATHS.TWINS, title: 'Explore Twins' },
  // { path: PATHS.SUBSCRIBED_TWINS, title: 'Subscribed Twins' },
  { path: PATHS.MY_TWIN, title: 'My Twin' },
];
