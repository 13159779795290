import { NavLink } from 'react-router-dom';

import { navItems } from '@/configs/nav/nav-items';

export const DesktopMenu = () => {
  return (
    <ul className="hidden lg:flex items-center gap-6 h-full">
      {navItems.map((item, i) => (
        <li key={i} className="h-full">
          <NavLink
            to={item.path}
            className={({ isActive }) =>
              `border-b-2 transition-all text-sm h-full flex items-center ${isActive ? 'border-primary' : 'border-transparent'}`
            }
          >
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
