import { useQuery } from 'react-query';
import { useAccount } from 'wagmi';

import { apiGetVault } from '@/services/user.api';

function useTwinVault() {
  const { isConnected } = useAccount();

  const { data, ...vaultQueryData } = useQuery({
    queryKey: ['vault-details'],
    queryFn: apiGetVault,
  });
  // console.log('Vault details: ', vaultDetails);

  const vaultData = data?.data?.data ?? {};

  return {
    isConnected,
    vaultData,
    ...vaultQueryData,
  };
}

export default useTwinVault;
